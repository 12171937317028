(function ($) {
  Drupal.behaviors.offerPromoMobileV1 = {
    attach: function (context, settings) {
      $(document).ready(function () {
        // Redirect to offers page when a user clicks on the template's wrapper div
        $('.nav-promo').on('.mobile-offer-promo-row', 'click', function (e) {
          e.preventDefault();
          var linkElement = $(this).find('.cta a');

          if (linkElement[0] === undefined) {
            var url = window.location.href,
              arr = url.split('/'),
              domain = window.location.hostname,
              offers = arr[0] + '//' + domain + '/offers';

            // Catch if undefined and redirect to offers page
            window.location.href = offers;
          } else {
            window.location.href = linkElement[0].href;
          }
        });
      });
    }
  };
})(jQuery);
